import { classNames } from "@/lib/utils";

const ExternalLink = ({ children, newTab = false, underline = false, overrideFont = false, href }) => {
  const newTabArgs = newTab && { target: "blank", rel: "noreferrer" };
  return (
    <a 
    href={href} 
    className={classNames(
      "hover:pointer hover:opacity-80", 
      underline && "underline",
      overrideFont && "font-foregen"
    )} 
    {...newTabArgs}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
