import { classNames } from "@/lib/utils";


export const Button = ({ onClick = () => {}, enabled = true, className, children }) => (
  <button
    type="button"
    onClick={enabled ? onClick : () => {}}
    className={classNames(
      "inline-flex items-center px-4 py-2 shadow-lg rounded-lg text-lg text-black font-foregen",
      "bg-gradient-to-b from-[#82FDBD] via-[#1CEBC3] to-[#00E0D4] hover:from-[#17d1ad] hover:via-[#17d1ad] hover:to-[#17d1ad]",
      !enabled && "opacity-60",
      className
    )}
  >
    {children}
  </button>
);

export const RedButton = ({ onClick = () => {}, enabled = true, className, children }) => (
  <Button
    onClick={onClick}
    enabled={enabled}
    className={classNames(className, "border-4 border-red-400")}
  >
    {children}
  </Button>
)

export default Button