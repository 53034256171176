import axios from 'axios'
import { ethers } from 'ethers'
import { formatEther, parseEther } from 'ethers/lib/utils'
import { FLAMEYS_CHAIN_ID, FLAMEYS_CONTRACT_ADDRESS, FLAMEYS_SIGNATURES_URL, FLAMEYS_COLLECTION_URL } from './constants'

export const getOpenseaURL = (tokenId) => {
  if (tokenId === undefined) return FLAMEYS_COLLECTION_URL
  
  let tokensBaseURL;
  if (FLAMEYS_CHAIN_ID === 1) tokensBaseURL = `https://opensea.io/assets/ethereum/${FLAMEYS_CONTRACT_ADDRESS}`
  if (FLAMEYS_CHAIN_ID === 5) tokensBaseURL = `https://testnets.opensea.io/assets/goerli/${FLAMEYS_CONTRACT_ADDRESS}`
  return `${tokensBaseURL}/${tokenId}`
}

export const classNames = (...classes) => classes.filter(Boolean).join(' ');

export const URIToHTTP = (uri) => {
  const IPFSProtocol = 'ipfs://'
  const IPFSGateway = "https://ipfs.mint.tribo.games/ipfs/";

  if (uri?.startsWith(IPFSProtocol)) {
    const hash = uri.slice(IPFSProtocol.length)
    return `${IPFSGateway}${hash}`
  }

  return uri
}

export const fetchJSONFromURI = async (uri) => {
  const _uri = URIToHTTP(uri)
  const response = await axios.get(_uri)
  return response?.data
}

export const getGasPrice = async (provider, multiplier) => {
  const price = await provider.getGasPrice();
  const str = formatEther(price);
  const eth = str * multiplier;
  return parseEther(eth.toFixed(18));
}

export const getSignature = async (address) => {
  try {
    const serverResponse = await axios.get(FLAMEYS_SIGNATURES_URL, {
      params: { address: address },
    });
    return serverResponse?.data
  } catch (e) {
    if (e.code !== "ERR_BAD_REQUEST") { // 404 would've been ok, just not whitelisted
      const message = e?.data?.message || e?.error?.message || e.message;
      console.log("Something went wrong", message);
    }
    return { r: ethers.constants.HashZero, s: ethers.constants.HashZero, v: 0 };
  }
}