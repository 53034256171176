import ExternalLink from "@/components/ExternalLink";

const Footer = () => (
  <>
    <footer className="static sm:absolute bottom-0 flex items-center justify-center bg-transparent text-sm text-gray-300 text-center w-full px-3 py-3 font-foregen">
      <p>
        <ExternalLink href={"https://www.tribo.games/terms"} newTab>
          Terms and conditions
        </ExternalLink>
      </p>
    </footer>
  </>
);

export default Footer
