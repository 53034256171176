import { useEffect, useState } from 'react';
import { useAccount, useNetwork } from 'wagmi';
import { useChainModal, useConnectModal } from '@rainbow-me/rainbowkit';
import { toast } from 'react-hot-toast';
import { Interface } from 'ethers/lib/utils';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import Image from 'next/future/image';
import { FLAMEYS_ABI, FLAMEY_MINTING_PERMISSION } from '@/lib/constants';
import { useReadonlyFlameysContract, useFlameysContract } from '@/lib/useFlameysContract';
import { URIToHTTP, fetchJSONFromURI, getGasPrice, getSignature, getOpenseaURL } from '@/lib/utils';
import Header from '@/components/Header';
import Badge from '@/components/Badge';
import Footer from '@/components/Footer';
import ProgressBar from '@/components/ProgressBar';
import Button, { RedButton } from '@/components/Button';
import Spinner from '@/components/Spinner';
import ExternalLink from '../components/ExternalLink';
import banner from "@/public/flameys_banner.png";

const MintingInfo = ({ children }) => (
  <div className="flex items-center py-8">
    <p className="text-tribo-teal font-bold text-left w-full">{children}</p>
  </div>
);

const Minting = () => {
  const { address } = useAccount()
  const { chain } = useNetwork()

  const { openConnectModal } = useConnectModal()
  const { openChainModal } = useChainModal()

  const readonlyContract = useReadonlyFlameysContract()
  const contract = useFlameysContract()

  const router = useRouter()
  const [initialDataLoading, setInitialDataLoading] = useState(true)
  const [supplyCap, setSupplyCap] = useState(undefined)
  const [currentSupply, setCurrentSupply] = useState(undefined)
  const [hiddenMetadata, setHiddenMetadata] = useState(undefined)
  const [isPaused, setIsPaused] = useState(true)
  const [isCollectionRevealed, setIsCollectionRevealed] = useState(undefined)
  const [signature, setSignature] = useState(undefined)
  const [mintStatus, setMintStatus] = useState(undefined)
  const [isMinting, setIsMinting] = useState(false)

  const isCorrectChain = !chain?.unsupported

  const mintAllowed = address && mintStatus === FLAMEY_MINTING_PERMISSION.ALLOWED
  const totalSupplyCapReached = address && mintStatus === FLAMEY_MINTING_PERMISSION.TOTAL_SUPPLY_CAP_REACHED
  const userAllowanceReached = address && mintStatus === FLAMEY_MINTING_PERMISSION.USER_ALLOWANCE_REACHED
  const invalidSignature = address && mintStatus === FLAMEY_MINTING_PERMISSION.INVALID_SIGNATURE

  const supplyPercentage = currentSupply && supplyCap ? currentSupply / supplyCap : 0
  const mintPriceDisplay = "FREE"
  const revealStatusDisplay = isCollectionRevealed ? "Revealed" : "Unrevealed";

  useEffect(() => {
    if (!readonlyContract) return

    const retrieveSupply = async () => {
      const supplyCapData = await readonlyContract.supplyCap()
      setSupplyCap(supplyCapData.toString())
      setInitialDataLoading(false);
      
      const currentSupplyData = await readonlyContract.totalSupply();
      setCurrentSupply(currentSupplyData.toString());
    }

    const retrieveHiddenMetada = async () => {
      const hiddenMetadataURI = await readonlyContract.hiddenMetadataURI()
      if (!hiddenMetadataURI) return

      const hiddenMetadata = await fetchJSONFromURI(hiddenMetadataURI)
      setHiddenMetadata(hiddenMetadata)
    }

    const retrieveCollectionRevealed = async () => {
      const collectionRevealedData = await readonlyContract.collectionRevealed()
      setIsCollectionRevealed(collectionRevealedData)
    }

    const retievePaused = async() => {
      const pausedData = await readonlyContract.paused()
      setIsPaused(pausedData)
    }

    retrieveSupply()
    retrieveHiddenMetada()
    retievePaused()
    retrieveCollectionRevealed()
  }, [readonlyContract])

  useEffect(() => {
    if (!readonlyContract) return
    if (!address) return
    if (isPaused) return

    const retrieveSignatureAndMintStatus = async () => {
      let signatureData = await getSignature(address)
      setSignature(signatureData);

      const mintStatusData = await readonlyContract.mintStatus(address, signatureData);
      setMintStatus(mintStatusData);
    }

    retrieveSignatureAndMintStatus()
  }, [readonlyContract, address, isPaused])

  const getNewTokenIdFromTxReceipt = (txReceipt) => {
    const flameysInterface = new Interface(FLAMEYS_ABI);
    return (
      txReceipt.logs
        // Parse log events
        .map((log) => {
          try {
            const event = flameysInterface.parseLog(log);
            return event;
          } catch (e) {
            return undefined;
          }
        })
        // Get rid of the unknown events
        .filter((event) => event !== undefined)
        // Keep only Transfer events
        .filter((event) => event.name === "Transfer")
        // Take the third argument which is the token id
        .map((event) => event.args[2].toString())
        // Take the first token id (there is only one)
        .shift()
    );
  };

  const handleMintFlamey = async () => {
    setIsMinting(true)
    const toastId = toast.loading("Sign transaction in wallet to mint your Flamey")
    try {
      const gasPrice = await getGasPrice(readonlyContract.provider, 1)
      const tx = await contract.mintPublic(signature, { gasPrice: gasPrice })
      toast.loading(`Minting your Flamey...`, { id: toastId })
      const receipt = await tx.wait()
      const tokenId = getNewTokenIdFromTxReceipt(receipt)
      toast.success("Successfully minted your Flamey", { id: toastId })
      router.push(`/flamey/${tokenId}`)
    } catch (e) {
      console.error(e)

      // User didn't sign transaction
      if (e?.code === 4001) {
        toast.dismiss(toastId)
        setIsMinting(false)
        return
      }

      // Display error message
      const message = e?.data?.message || e?.error?.message || e.message;
      toast.error("Something went wrong minting your Flamey", {id: toastId})
      toast.error(message)
    }
    setIsMinting(false)
  }

  if (initialDataLoading) return <></>;

  return (
    <>
      <div className="flex flex-col justify-center items-center w-full">
        <div className='flex justify-center -mt-4 sm:-mt-16 w-1/2 sm:w-1/4'>
          <Image src={banner} priority alt="Flameys" />
        </div>
        <div className="flex flex-col sm:flex-row gap-4 lg:gap-8 px-8 py-8 lg:py-12 lg:w-3/4 items-center sm:items-start bg-tribo-glass-gradient m-8 rounded-3xl">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          {hiddenMetadata?.image && <img src={URIToHTTP(hiddenMetadata?.image)} alt="Flamey" className="h-64 w-64 rounded-lg" />}
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col gap-2">
              <div className="flex flex-col sm:flex-row justify-end items-center">
                <div className="flex gap-1">
                  {mintPriceDisplay && <Badge>{mintPriceDisplay}</Badge>}
                  {revealStatusDisplay && <Badge>{revealStatusDisplay}</Badge>}
                </div>
              </div>
              <p className="pt-4 text-base font-medium">Two thousand unique and colorful Flameys gathered at the campfire to celebrate Tribo&apos;s launch and future projects. Claim yours for free now and showcase your unique style within the Tribo Community!</p>
              {!userAllowanceReached && <p className="text-sm font-medium text-gray-300">Check the Flameys collection on <ExternalLink href={getOpenseaURL(undefined)} newTab underline>OpenSea</ExternalLink>.</p>}
              <p className="text-sm font-medium text-gray-300">Don&apos;t forget to join our <ExternalLink href="https://discord.gg/Ju5PsKQAQu" newTab underline>Discord</ExternalLink>.</p>
            </div>
            <div>
              {address && isPaused && <MintingInfo>Minting is not possible at the moment, come back later.</MintingInfo>}
              {address && !isPaused && (
                <>
                  {totalSupplyCapReached && <MintingInfo>All {supplyCap} Flameys have been minted!</MintingInfo>}
                  {userAllowanceReached && <MintingInfo>You have minted all the Flameys you were allowed to mint! Find your Flamey on <ExternalLink href={getOpenseaURL(undefined)} newTab underline>OpenSea</ExternalLink>.</MintingInfo>}
                  {invalidSignature && <MintingInfo>You are not on the whitelist. Check out the instructions on <ExternalLink href="https://discord.gg/aqK9anhQZC" newTab underline>Discord</ExternalLink> to get access.</MintingInfo>}
                </>
              )}
              <div className="py-6">
                {currentSupply && <ProgressBar progress={supplyPercentage * 100} />}
              </div>
              <div className="flex flex-col gap-6 md:flex-row md:items-center justify-between ">
                <div className="text-gray-300 text-sm">
                  {currentSupply && <p>{currentSupply} of {supplyCap} Flameys minted</p>}
                </div>
                <div className="flex justify-center">
                  {!address && <Button onClick={openConnectModal}>Connect &amp; Mint</Button>}
                  {address && !isCorrectChain && <RedButton onClick={openChainModal}>Switch Network</RedButton>}
                  {address && isCorrectChain && !isPaused && mintAllowed && <Button onClick={handleMintFlamey} enabled={!isMinting}>
                    {isMinting 
                    ? <span className="inline-flex gap-2 items-center"><Spinner className="h-4 w-4" />Minting</span> 
                    : <>Mint Flamey {mintPriceDisplay && <>({mintPriceDisplay})</>}</>}
                  </Button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default function Home() {
  return (
    <>
      <NextSeo
        title="Tribo Flameys"
        description="Claim yours and showcase your unique style within the Tribo Games community!"
        canonical="https://mint.tribo.games"
        openGraph={{
          url: 'https://mint.tribo.games',
          title: 'Tribo Flameys',
          description: 'Claim yours and showcase your unique style within the Tribo Games community!',
          images: [
            { url: '/flamey.png' },
          ],
          site_name: 'Tribo Flameys',
        }}
      />
      <Header />
      <Minting />
      <Footer />
    </>
  )
}
