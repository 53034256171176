import { useContract, useProvider, useSigner } from 'wagmi';
import { FLAMEYS_CONTRACT_ADDRESS, FLAMEYS_ABI } from '@/lib/constants';

export const useFlameysContract = () => {
  const { data: signer } = useSigner()
  const contract = useContract({
    addressOrName: FLAMEYS_CONTRACT_ADDRESS,
    contractInterface: FLAMEYS_ABI,
    signerOrProvider: signer
  })
  return contract
}

export const useReadonlyFlameysContract = () => {
  const provider = useProvider()
  const contract = useContract({
    addressOrName: FLAMEYS_CONTRACT_ADDRESS,
    contractInterface: FLAMEYS_ABI,
    signerOrProvider: provider
  })
  return contract
}
