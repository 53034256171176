import Image from "next/future/image";
import Link from "next/link";

import logo from "@/public/tribo-logo.png"
import FlameyConnectButton from "./ConnectButton";
import ExternalLink from "./ExternalLink";

const Header = () => {
  return (
    <>
      <div className="flex items-center justify-between px-8 py-4 bg-transparent">
        <Link href="https://tribo.games">
          <a className="hover:pointer">
            <Image src={logo} priority alt="Logo" className="w-20 h-20" />
          </a>
        </Link>
        <div className="flex items-center gap-4">
          <ExternalLink href="https://discord.gg/hYQ5Kmvpjn" newTab overrideFont>Support</ExternalLink>
          <FlameyConnectButton />
        </div>
      </div>
    </>
  )
}


export default Header